import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useReactToPrint } from "react-to-print";
import './ViewDemand.css';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import './ViewDemandModal.css';
import axiosInstance from '../../../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { CURRENT_DEMAND } from '../../../Redux/constants/demandConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import Loader from '../../Loader/Loader';
import ApproveModal from '../../Modal/DemandModal/ApproveModal';
import { clearError, getDemandById, getAllDemand, getPreviewDemand } from '../../../actions/demandAction';
import TablePagination from '@mui/material/TablePagination';
import { IoEye } from "react-icons/io5";
import { AiFillInteraction } from "react-icons/ai";
import ReactTable from '../../ReactTable'; // Ensure the path is correct
import Tippy from '@tippyjs/react';
import TemplatePrint from '../printTemplate/TemplatePrint';
import TableComponentId from '../../Table Component/tableComponent';

const URL = process.env.REACT_APP_BASE_URL;

function ViewDemandedReciept() {
    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentPDF = useRef();
    let filteredDemands;
    const viewDetail = "View Details"
    const previewDemand = "Preview Demand"
    const { loading, allDemand, error } = useSelector((state) => state.allDemand);
    const [currentDemand, setCurrentDemand] = useState([]);
    const [currentDemandedProducts, setCurrentDemandedProducts] = useState([]);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [_id, setId] = useState('');
    const [searchApplicationId, setSearchApplicationId] = useState('');
    const [searchSubject, setSearchSubject] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
        dispatch(getAllDemand());
    }, [dispatch, error, alert]);

    const fetchDemand =async (_id) => {
        // handlePrint()
        dispatch(getPreviewDemand(_id))
        console.log(_id)
        // await axiosInstance.get(`${URL}/demand/getDemandById/${_id}`).then((response) => {
        //     dispatch({ type: CURRENT_DEMAND, payload: { currentDemand: [response.data.demandedProduct] } });
        //     setId(_id);
        // });
    };

    const viewDemand = (_id) => {
        console.log(_id)
        console.log(_id?._id)
        fetchDemand(_id._id);
        navigate("/previewDemand")
    };

    const handlePrint = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Demand"
    });

    const handleApprove = (demand) => {
        dispatch(getDemandById(demand._id));
        setId(demand._id);
        setCurrentDemand(demand);
        const products = demand.products;
        setCurrentDemandedProducts(products);
        setIsApproveModalOpen(true);
    };

    useEffect(() =>{
        filteredDemands = allDemand
    }, [allDemand])

    filteredDemands = allDemand?.filter(demand =>
        demand.applicationId.toLowerCase().includes(searchApplicationId.toLowerCase()) &&
        demand.subject.toLowerCase().includes(searchSubject.toLowerCase()) &&
        demand.date.toLowerCase().includes(searchDate.toLowerCase()) &&
        (searchStatus === '' || demand.status.toLowerCase() === searchStatus.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddDemandClick = () => {
        navigate('/demand');
    };

    const indexOfLastDemand = page * rowsPerPage + rowsPerPage;
    const indexOfFirstDemand = page * rowsPerPage;
    const currentDemands = filteredDemands?.slice(indexOfFirstDemand, indexOfLastDemand);

    const columns = [
        { field: "applicationId", label: "Demand No", addedText: "applicationId" },
        { field: "subject", label: "Subject" },
        { field: "date", label: "Date", format: "date" },
        { field: "status", label: "Status" },
      ];


       
      const actions = [
        {
            label: "View Details",
            color: "yellow",
            handler: (itemId) => handleApprove(itemId),
          },
          {
            label: "Preview Demand",
            color: "yellow",
            handler: (itemId) => viewDemand(itemId),
          },
       
      ];      

    const { roleTask } = useSelector(
        (state) => state.userData
      );

      var task = false;
      task = roleTask.find((e) => e?.task_id?.name === "Add Product Demand" && e.status === true);
      


    return (
        <Fragment>
            <div className={`Demand`}>
            <div ref={componentPDF} className='print-div' style={{ display: 'none' }}>
                    <TemplatePrint />
                </div>
                <div className="secondContainer">   
                    <div className="contentt-box">
                        <div className="heading-container">
                            <h3>{"View Demand"}</h3>
                            <h5>
                                <span className="total-records">
                                    {"Total Records"}&nbsp;&nbsp;
                                    <EventAvailableIcon fontSize="small" />
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Enter Application ID"
                            value={searchApplicationId}
                            onChange={(e) => setSearchApplicationId(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Enter Subject"
                            value={searchSubject}
                            onChange={(e) => setSearchSubject(e.target.value)}
                        />
                        <select
                            value={searchStatus}
                            onChange={(e) => setSearchStatus(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                        </select>
                    </div>
                    <div className='table-container'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <TableComponentId  
                        data={filteredDemands}
                        columns={columns}
                        actions={actions}
                        viewDetail={viewDetail}
                        previewDemand= {previewDemand} />
                    )}
                </div>
                </div>
            </div>
            {isApproveModalOpen && <ApproveModal
                    isApproveModalOpen={isApproveModalOpen}
                    setIsApproveModalOpen={setIsApproveModalOpen}
                    currentDemand={currentDemand}
                    currentDemandedProducts={currentDemandedProducts}
                    request_id={_id}
                />}
            {/* <div className="main-page-container">
                <div ref={componentPDF} className='print-div' style={{ display: 'none' }}>
                    <TemplatePrint />
                </div>
                <div className='pageName_And_Button'>
                    <h2>View Demand</h2>

            {task? <button className="button-yellow" onClick={handleAddDemandClick}>Add Demand</button>: null}

                    
                </div>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Enter Application ID"
                        value={searchApplicationId}
                        onChange={(e) => setSearchApplicationId(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter Subject"
                        value={searchSubject}
                        onChange={(e) => setSearchSubject(e.target.value)}
                    />
                    <select
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                    </select>
                </div>
                <div className='table-container'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <ReactTable data={currentDemands} columns={columns} />
                    )}
                </div>
                <TablePagination
                    component="div"
                    count={filteredDemands?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            )

            <div>
                {isApproveModalOpen && <ApproveModal
                    isApproveModalOpen={isApproveModalOpen}
                    setIsApproveModalOpen={setIsApproveModalOpen}
                    currentDemand={currentDemand}
                    currentDemandedProducts={currentDemandedProducts}
                    request_id={_id}
                />}
            </div> */}
        </Fragment>
    );
}

export default ViewDemandedReciept;

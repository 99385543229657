import {
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  GET_ALL_ROLE_REQUEST,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAIL,
  GET_ALL_TASK_REQUEST,
  GET_ALL_TASK_SUCCESS,
  GET_ALL_TASK_FAIL,
  ASSIGN_TASKS_TO_ROLES_SUCCESS,
  ASSIGN_TASKS_TO_ROLES_FAIL,
  GET_ROLE_TASKS_REQUEST,
  GET_ROLE_TASKS_SUCCESS,
  GET_ROLE_TASKS_FAIL,
  UPDATE_ROLE_TASK_SUCCESS,
  UPDATE_ROLE_TASK_FAIL,
  CLEAR_ERROR,
  CLEAR_MESSAGE,
  UPDATE_ROLE_TASK_REQUEST,
  GET_ASSIGNED_ROLE_TASKS_REQUEST,
  GET_ASSIGNED_ROLE_TASKS_SUCCESS,
  GET_ASSIGNED_ROLE_TASKS_FAIL,
  UPDATE_ASSIGNED_ROLE_TASKS_REQUEST,
  UPDATE_ASSIGNED_ROLE_TASKS_SUCCESS,
  UPDATE_ASSIGNED_ROLE_TASKS_FAIL
} from "../constants/roleConstant";

export const roleReducer = (state = { allRole: [], allTask: [], roleTasks: [] }, action) => {
  switch (action.type) {
    case ADD_ROLE_REQUEST:
    case GET_ALL_TASK_REQUEST:
    case GET_ALL_ROLE_REQUEST:
    case GET_ROLE_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ROLE_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        loading: false,
        allRole: action.payload,
      };
    case GET_ALL_TASK_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        loading: false,
        allTask: action.payload,
      };
    case GET_ROLE_TASKS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        roleTasks: action.payload,
      };
    case ASSIGN_TASKS_TO_ROLES_SUCCESS:
      return {
        ...state,
        loading: false
      };
      case ADD_ROLE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case GET_ALL_TASK_FAIL:
    case GET_ALL_ROLE_FAIL:
    case ASSIGN_TASKS_TO_ROLES_FAIL:
      case ADD_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
      case CLEAR_MESSAGE:
        return {
          ...state,
          message: null,
        }
    default:
      return state;
  }
};


export const updateRoleTaskReducer = (state = { updateRoleTasks: [] }, action) => {
  switch (action.type) {
    case UPDATE_ROLE_TASK_REQUEST:
      return {
        ...state,
        updateRoleTasksLoading: true
      };
 
    case UPDATE_ROLE_TASK_SUCCESS:
      return {
        ...state,
        updateRoleTasksLoading: false,
        updateRoleTasks: action.payload,
      };
      case UPDATE_ROLE_TASK_FAIL:
        return {
          ...state,
          updateRoleTasksLoading: false,
          updateRoleTasksError: action.payload,
        };
    case CLEAR_ERROR:
      return {
        ...state,
        updateRoleTasksError: null
      };
    default:
      return state;
  }
};

export const getAssignedRoleTaskReducer = (state = { assignedRoleTask: [] }, action) => {
  switch (action.type) {
    case GET_ASSIGNED_ROLE_TASKS_REQUEST:
      return {
        ...state,
        assignedRoleTaskLoading: true
      };
 
    case GET_ASSIGNED_ROLE_TASKS_SUCCESS:
      return {
        ...state,
        assignedRoleTaskLoading: false,
        assignedRoleTask: action.payload,
      };
      case GET_ASSIGNED_ROLE_TASKS_FAIL:
        return {
          ...state,
          assignedRoleTaskLoading: false,
          assignedRoleTaskError: action.payload,
        };
    case CLEAR_ERROR:
      return {
        ...state,
        assignedRoleTaskError: null
      };
    default:
      return state;
  }
};

export const updateAssignedRoleTaskReducer = (state = { updateAssignedTask: [] }, action) => {
  switch (action.type) {
    case UPDATE_ASSIGNED_ROLE_TASKS_REQUEST:
      return {
        ...state,
        updateAssignedTaskLoading: true
      };
 
    case UPDATE_ASSIGNED_ROLE_TASKS_SUCCESS:
      return {
        ...state,
        updateAssignedTaskLoading: false,
        updateAssignedTask: action.payload,
      };
      case UPDATE_ASSIGNED_ROLE_TASKS_FAIL:
        return {
          ...state,
          updateAssignedTaskLoading: false,
          updateAssignedTaskError: action.payload,
        };
    case CLEAR_ERROR:
      return {
        ...state,
        updateAssignedTaskError: null
      };
    default:
      return state;
  }
};

import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { State } from '../DemandContext/context.js';
import PrintLaserTable from "./../../Table Component/printLaserTable"
import { getActiveSignatureRecord } from "../../../actions/signatureRecordAction.js";
export default function PrintTemplate({applicationId, date, subject, description, demandList   }) {
        const dispatch = useDispatch()
        const {activeSignatureRecord, activeSignatureRecordLoading} = useSelector((state)=> state.activeSignatureRecord)
useEffect(()=>{
        dispatch(getActiveSignatureRecord())
}, [])
const columns = [
        { field: "name", label: "Name" },
        { field: "specifications", label: "Specifications" },
        { field: "received_quantity", label: "Received Quantity" },
        { field: "quantity", label: "Quantity" },
      ];


  return (
    <>
      <div className="headerHeading">
                    <h2>Pir Mehr Ali Shah Arid Agriculture University Rawalpindi</h2>
                    <p>University institute of information Technology </p>
                </div>
                <div className="dateTemplate">
                    <h3>PMAS-AAUR/UIIT/{ applicationId}</h3>
                    <h4>Dated: {date}</h4>
                </div>
                <div className='subjectTemplate'>
                        <h3>Subject: <p>{subject}</p></h3><br/>
                        <p><ul>{ description }</ul></p>
                </div>
                <div className="printTable">
                {
                    <PrintLaserTable 
                        data={demandList} 
                        columns={columns} />
                }
               
                </div>
                <div className='signatureArea'>
                        <h2>{activeSignatureRecord[0]?.name}</h2>
                        <h3>{activeSignatureRecord[0]?.designation}</h3>
                </div>
                <div className='leftSignatureArea'>
                        <h2>Director UIIT</h2>
                        
                </div>
                <div className='leftSignatureArea'>
                        <h2>Deputy Registrar(TR-II)</h2>
                </div>
 
    </>
  );
}

import { ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_FAIL, ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_REQUEST, ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_SUCCESS } from '../constants/demandConstants';
import {
    GET_ALL_PRODUCT_TYPE_REQUEST,
    GET_ALL_PRODUCT_TYPE_SUCCESS,
    GET_ALL_PRODUCT_TYPE_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_TYPE_REQUEST,
    ADD_PRODUCT_TYPE_SUCCESS,
    ADD_PRODUCT_TYPE_FAIL,
    GET_ALL_PRODUCT_REQUEST,
    GET_ALL_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_FROM_LOCATION_REQUEST,
    GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS,
    GET_ALL_PRODUCT_FROM_LOCATION_FAIL,
    CLEAR_MESSAGE,
    CLEAR_ERROR,
    GET_PRODUCTTYPE_BYID_REQUEST,
    GET_PRODUCTTYPE_BYID_SUCCESS,
    GET_PRODUCTTYPE_BYID_FAIL,
    UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST,
    UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS,
    UPDATE_PRODUCTTYPESTATUS_BYID_FAIL
} from '../constants/productConstants'

export const productReducer = (state = { allProductType: [],message:'',allProduct:[] }, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_TYPE_REQUEST:
    case ADD_PRODUCT_TYPE_REQUEST:
    case GET_ALL_PRODUCT_FROM_LOCATION_REQUEST:
    case GET_ALL_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        allProductType: action.payload,
      };
    case GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS:
    case GET_ALL_PRODUCT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        allProduct:action.payload,
      };
    case ADD_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case GET_ALL_PRODUCT_TYPE_FAIL:
    case ADD_PRODUCT_TYPE_FAIL:
    case GET_ALL_PRODUCT_FROM_LOCATION_FAIL:
    case GET_ALL_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message:null,
      };
    default:
      return state;
  }
};


export const addProductReducer = (state = { addProduct: [] }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        addProductLoading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        addProductLoading: false,
        addProduct:action.payload
      };
    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        addProductLoading: false,
        addProductError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        addProductError: null,
      };
    default:
      return state;
  }
};

export const updateProductQuantityReducer = (state = { updateProductQuantity: [] }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_REQUEST:
      return {
        ...state,
        updateProductQuantityLoading: true,
      };
    case ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_SUCCESS:
      return {
        ...state,
        updateProductQuantityLoading: false,
        updateProductQuantity:action.payload
      };
    case ADD_PRODUCT_QUANTITY_THROUGH_DEMAND_FAIL:
      return {
        ...state,
        updateProductQuantityLoading: false,
        updateProductQuantityError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        updateProductQuantityError: null,
      };
    default:
      return state;
  }
};


export const productTypeByIdReducer = (state = { producTypeById: []}, action) => {
  switch (action.type) {
    case GET_PRODUCTTYPE_BYID_REQUEST:
   
      return {
        ...state,
        producTypeByIdLoading: true,
      };
    case GET_PRODUCTTYPE_BYID_SUCCESS:
      return {
        ...state,
        producTypeByIdLoading: false,
        producTypeById: action.payload,
      };
    case GET_PRODUCTTYPE_BYID_FAIL:
      return {
        ...state,
        producTypeByIdLoading: false,
        producTypeByIdError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        producTypeByIdError: null,
      };
    default:
      return state;
  }
};


export const updateProductTypeStatusReducer = (state = { producTypeStatus: []}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST:
   
      return {
        ...state,
        producTypeStatusLoading: true,
      };
    case UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS:
      return {
        ...state,
        producTypeStatusLoading: false,
        producTypeStatus: action.payload,
      };
    case UPDATE_PRODUCTTYPESTATUS_BYID_FAIL:
      return {
        ...state,
        producTypeStatusLoading: false,
        producTypeStatusError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        producTypeStatusError: null,
      };
    default:
      return state;
  }
};

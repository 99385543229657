import { createContext, useEffect, useRef, useState } from "react";


export const State = createContext();

export default function StateContext({ children }) {
    const [applicationId, setApplicationId] = useState("");
    const [date, setDate] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [locationId, setLocationId] = useState("");
    const [productId, setProductId] = useState()
    const [name, setName] = useState()
    const [specifications, setSpecifications] = useState()
    const [formattedDate, setFormattedDate] = useState()
    const [productDescription, setProductDescription] = useState()
    const [type, setType] = useState()
    const [company, setCompany] = useState()
    const [quantity, setQuantity] = useState()
    const [demandList, setDemandList] = useState([])
    
    const handleSubmit = async () => {
        console.log('hfalei')
        const status = "pending";
          /////******* set Data in list first time  ********//////
          const newItems = {
            _id: productId,
            name,
            specifications,
            productDescription,
            type,
            company,
            quantity,
            applicationId,
            date,
            subject,
            description,
            locationId,
            // status,
            // dateInput
          };
          setDemandList([...demandList, newItems]);
      };

  const context = {
    applicationId, 
    setApplicationId,
    date, 
    setDate,
    subject, 
    setSubject,
    description, 
    setDescription,
    locationId, 
    setLocationId,
    demandList, 
    setDemandList,
    handleSubmit,
    productId, setProductId,
    name, setName,specifications, setSpecifications,
    productDescription, setProductDescription,
    type, setType,company, setCompany,quantity, setQuantity, formattedDate, setFormattedDate

  };

  return <State.Provider value={context}>{children}</State.Provider>;
}

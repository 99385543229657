import React, { useState, useRef, useEffect } from "react";
import { Icon } from "./Icon";
import { useLocation, useNavigate } from 'react-router-dom';
import images from "./aridLogo.png"
import * as AiIcons from "react-icons/ai";
import * as iconss from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import { SiExpensify, SiChakraui } from "react-icons/si";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import MoneyIcon from "@mui/icons-material/Money";
import { useDispatch, useSelector } from "react-redux";
// import { Button } from "@mui/material";
// import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  HiOutlineCurrencyDollar,
} from "react-icons/hi";
import "./newSidebar.css"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
const menuItems = [
  {
    name: "Dashboard",
    icon: <iconss.FaFileInvoice  className='svgClass'/>,
    // cName: "nav-text",
    path: "/",
    roles: ["SuperAdmin", "Admin", "Administrator", "Salesman", "Stock Manager"],
  },
  {
    name: "Catelog",
    icon: <iconss.FaFileInvoice  className='svgClass'/>,
    // cName: "nav-text",
    roles: ["SuperAdmin", "Admin", "Administrator", "Salesman", "Stock Manager"],

    items: [
      {
        name: "All Products",
        path: "/products",
        permission: "View Product",
      },
      {
        name: "Available Products",
        path: "/availableproduct",
        permission: "View Product",
      },
      
    ],
  },
  {
    name: "Records",
    icon: <LiaFileInvoiceDollarSolid  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "SuperAdmin", "Administrator"],

    items: [
      {
        name: "Product Type",
        path: "/producttype",
        permission: "View Product Type",
      },
      {
        name: "Company",
        path: "/company",
        permission: "View Product Company",
      },
      {
        name: "Designation",
        path: "/designation",
        permission: "View User Designation",
      },
      {
        name: "Issueing Authority",
        path: "/viewSignatureRecord",
        icon: <SiExpensify />,
        cName: "nav-text",
        permission: "View Signature Record",
      }
    ],
  },
  {
    name: `Demands`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Administrator", "SuperAdmin", "Stock Manager"],
    items: [
      {
        name: "Generate Demand",
        path: "/demand",
        permission: "Add Product Demand",
      },
      {
        name: "View Demands",
        path: "/viewDemand",
        permission: "View Product Demand",
      },
      
    ],
  },
  {
    name: `Requests`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "SuperAdmin", "Salesman", "Stock Manager"],
    items: [
      {
        name: "View Requests",
        path: "/requests",
        permission: "View Product Request",
      },
    ],
  },
  {
    name: `Consolidated Invoices`,
    icon: <AiIcons.AiFillHome  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "Administrator", "SuperAdmin", "Salesman", "Stock Manager"],
    items: [
      {
        name: "Demand",
        path: "/demandConsolidated",
        permission: "View Demand Consolidated",
      },
      {
        name: "Request",
        path: "/requestConsolidated",
        permission: "View Request Consolidated",
      },
    ],
  },
  {
    name: "Users",
    icon: <HiOutlineCurrencyDollar  className='svgClass'/>,
    cName: "nav-text",
    roles: ["SuperAdmin", "Admin", "Salesman", "Stock Manager"],

    items: [
      {
        name: "User Approval",
        path: "/registrationApproval",
        permission: "User Approval",
      },
      {
        name: "User Status",
        path: "/userStatus",
        permission: "User Status",
      },
      // {
      //   name: "View User",
      //   path: "/viewuser",
      //   permission: "View User",
      // },
    ],
  },

  {
    name: "Role Management",
    icon: <HiOutlineCurrencyDollar  className='svgClass'/>,
    cName: "nav-text",
    roles: ["SuperAdmin","Admin", "Salesman", "Stock Manager"],
    items: [
      // {
      //   name: "Role",
      //   path: "/role",
      //   permission: "View User Role",
      // },
      {
        name: "Role",
        path: "/roletaskedit",
        permission: "Role Task Edit",
      },
    ],
  },
 
  {
    name: "Task",
    icon: <LiaFileInvoiceDollarSolid  className='svgClass'/>,
    cName: "nav-text",
    roles: ["Admin", "SuperAdmin", "Administrator"],

    items: [
      {
        name: "Task",
        path: "/task",
        permission: "View Role Task",
      },
    ],
  },
  
 
];


const NavHeader = ({ onClick }) => (
  <header className="sidebar-header">
    <button onClick={onClick} type="button">
      <ArrowBackIcon className='svgClass'/> 
    </button>
    <img src={images} className="sidebar-logo" alt="Logo" />
  </header>
);

const NavButton = ({ onClick, name, icon, isActive,path, hasSubNav }) => {
  return(
  <button
    type="button"
    onClick={() => onClick(name, path)}
    className={isActive ? 'active' : ''}
  >
    {icon && <Icon icon={icon} />}
    <span>{`${name}`}</span>
    {hasSubNav && <Icon icon={<ArrowDropDownIcon  className='svgClass'/>} />}
  </button>)
};

const SubMenu = ({ setIsOpen, item, activeItem, handleClick, props }) => {
  const location = useLocation()
    const { taskArray } = useSelector((state) => state.userData);
  const navRef = useRef(null);
  const [activeSubItem, setActiveSubItem] = useState("");
  const navigate = useNavigate()
  const isSalesman = JSON.parse(localStorage.getItem("isSalesman"));
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const isAdministrator = JSON.parse(localStorage.getItem("isAdministrator"));
  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  const handleLinkClick =(link,name)=>{
    // console.log(link, name)
    // console.log(location)
    navigate(`${link}`)
    setIsOpen(false)
    // handleClick()
  }

  
  const isSubNavOpen = (item, items) =>
    items.some((i) => i === activeItem) || item === activeItem;

  return (
    <div
      className={`sub-nav ${isSubNavOpen(item.name, item.items) ? 'open' : ''}`}
      style={{
        height: !isSubNavOpen(item.name, item.items)
          ? 0
          : navRef.current?.clientHeight + 5,
      }}
    >
      <div ref={navRef} className="sub-nav-inner">
        {item?.items.map((subItem) => (
           (taskArray?.includes(subItem.permission) ||
           subItem?.permission?.includes("superAdmin")) &&  ( 
           <NavButton
              // onClick={handleClick}
              onClick={() => {
                handleLinkClick(subItem.path, subItem.name);
                // !item.subItems && setShowMenu(false);
              }}
              name={subItem.name}
              isActive={location.pathname === subItem.path}
              key={subItem.name}
            />)
         
        ))}
      </div>
    </div>
  );
};

export const Sidebar = ({ isOpen, setIsOpen, props }) => {

  const [activeItem, setActiveItem] = useState("");
  const {user} = useSelector((state)=> state.userData);
  const navigate = useNavigate();
  const isSalesman = user?.role_id?.name;
  const isAdmin = user?.role_id?.name;
  const isAdministrator = user?.role_id?.name;
  const isSuperAdmin = user?.role_id?.name === "SuperAdmin";
  const isStockManager = user?.role_id?.name;
  useEffect(()=>{
    console.log(user)
  }, [])
  // const isSalesman = JSON.parse(localStorage.getItem("isSalesman"));
  // const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  // const isAdministrator = JSON.parse(localStorage.getItem("isAdministrator"));
  // const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  // const isStockManager = JSON.parse(localStorage.getItem("isStockManager"));
  const handleDrawerCloseForLogout = async () => {
    setIsOpen(false);
    // await logout()
    // dispatch(logout());
    // dispatch({ type: LOAD_USER_FAIL });
    navigate("/login");
  };
  const userPanel = () => {
    navigate("/settings");
    // navigate("/updateUserProfile");
  };
  const handleClick = (item, path) => {
    console.log(item)
    if(path)
        navigate(path);
    setActiveItem(item !== activeItem ? item : "");
  };

  return (
    <aside className={`sidebar ${isOpen ? "open" : ""}`}>
      <nav className="sidebar-nav">
        <NavHeader onClick={() => setIsOpen(false)} />
        {menuItems?.map((item) => (
            <div key={item.name}>
              {!item?.items && (
                // console.log(isSuperAdmin)
                 (isSalesman && item?.roles?.includes("Salesman")) ||
                 (isAdmin && item?.roles?.includes("Admin")) ||
                 (isSuperAdmin && item?.roles?.includes("SuperAdmin")) ||
                 (isAdministrator && item?.roles?.includes("Administrator")) ||
                 (isStockManager && item?.roles?.includes("Stock Manager")) ? (
                <NavButton
                  onClick={handleClick} 
                  name={item.name}
                  icon={item.icon}
                  path={item?.path}
                  isActive={activeItem === item.name}
                  hasSubNav={!!item?.items}
                />):(<></>)
              )}
              {item?.items && (<>{
                (isSalesman && item?.roles?.includes("Salesman")) ||
                (isAdmin && item?.roles?.includes("Admin")) ||
                (isSuperAdmin && item?.roles?.includes("superAdmin")) ||
                (isAdministrator && item?.roles?.includes("Administrator")) ||
                (isStockManager && item?.roles?.includes("Stock Manager")) ? (<>
                  <NavButton
                    onClick={handleClick}
                    name={item.name}
                    icon={item.icon}
                    isActive={activeItem === item.name}
                    hasSubNav={!!item.items}
                    key={item.name}
                  />
                  <SubMenu
                    setIsOpen = {setIsOpen}
                    activeItem={activeItem}
                    handleClick={handleClick}
                    item={item}
                    props={props}
                  />
                </>):(null)
              }</>
                
              )}
            </div>
          ))}
      </nav>
    </aside>
  );
};


import { combineReducers } from 'redux';
import { requestReducer,productQuantityReducer,requestedProductReducer,currentRequestReducer, updateRequestStatusReducer, getRequestConsolidatedReducer} from '../reducer/requestReducer';
import { demandReducer,allProductReducer,allLocationReducer,allDemandReducer, getPreviewDemandReducer, postDemandReducer, updateDemandAndProductStatusQuantityReducer, getDemandConsolidatedReducer} from '../reducer/demandReducer';
import { getAssignedRoleTask, getAssignedRoleTaskReducer, roleReducer, updateAssignedRoleTaskReducer, updateRoleTaskReducer } from '../reducer/roleReducer';
import {addTaskReducer} from '../reducer/taskReducer';
import { addProductReducer, productReducer, productTypeByIdReducer, updateProductQuantityReducer, updateProductTypeStatusReducer } from '../reducer/productReducer';
import { getAllUserReducer, updateUserDataReducer, userReducer } from '../reducer/userReducer';
import { designationReducer } from '../reducer/designationReducer';
import { addSignatureRecordReducer, getActiveSignatureReducer, updateSignatureRecordReducer } from '../reducer/signatureRecordReducer';
import { dashboardReducer, getDashboardDataReducer } from '../reducer/dashboardReducer';
import { allRegistrationApproval } from '../reducer/registrationApprovalReducer';
import {userDataReducer,forgotPasswordReducer,profileReducer} from '../reducer/userDataReducer'
import { companyReducer, getCompanyByIdReducer, updateCompanyReducer } from '../reducer/companyReducer';

export const rootReducer = combineReducers({
    requests:requestReducer,
    updateRequestsStatus:updateRequestStatusReducer,
    quantity:productQuantityReducer,
    requestedProduct:requestedProductReducer,
    demandReducer:demandReducer,
    postDemand: postDemandReducer,
    previewDemand:getPreviewDemandReducer,
    allProduct:allProductReducer,
    allLocation:allLocationReducer,
    allDemand:allDemandReducer,
    role:roleReducer,
    updateRoleTasks:updateRoleTaskReducer,
    producTypeStatus:updateProductTypeStatusReducer,
    addTask:addTaskReducer,
    product:productReducer,
    addProduct: addProductReducer,
    updateProductQuantity: updateProductQuantityReducer,
    updateDemandAndProduct: updateDemandAndProductStatusQuantityReducer,
    productTypeById:productTypeByIdReducer,
    user:userReducer,
    updateUserData:updateUserDataReducer,
    assignedRoleTask: getAssignedRoleTaskReducer,
    updateAssignedTask: updateAssignedRoleTaskReducer,
    allUser: getAllUserReducer,
    designation:designationReducer,
    signatureRecord:addSignatureRecordReducer,
    updateSignatureRecord:updateSignatureRecordReducer,
    activeSignatureRecord: getActiveSignatureReducer,
    dashboard:dashboardReducer,
    updateCompany: updateCompanyReducer,
    companyById: getCompanyByIdReducer,
    allRegistration:allRegistrationApproval,
    // currentRequest:currentRequestReducer,
    userData:userDataReducer,
    profile:profileReducer,
    forgotPassword:forgotPasswordReducer,
    company:companyReducer,
    dashboardData: getDashboardDataReducer,
    demandConsolidateRecord: getDemandConsolidatedReducer,
    requestConsolidateRecord: getRequestConsolidatedReducer
});
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import PrintTemplate from "./printTemplate";
import { useSelector } from "react-redux";
export default function  Table() {
 const demandPrint = useRef(null)
 const {previewDemand, previewDemandLoading, previewDemandError} = useSelector((state)=> state.previewDemand)
  const navigate = useNavigate();


  const [selectedPrinter, setSelectedPrinter] = useState();

  
  const handleSelectPrinter = (printer) => {
    // setSelectedPrinter(printer);
    // setselected(true)
  };

  const backHandle = () => {
    navigate("/viewDemand");
  };

  return (
    <>
      <div className={`Demand`}>
      <div className="secondContainer">
      <div className="demandProduct-box">
          <button
            // positive
            onClick={backHandle}
            className="printButton"
            
            >
            Back
          </button>
          <ReactToPrint
            trigger={() => (
              
              <button className="printButton" disabled={previewDemand?.status !=="Pending"}>
                Print / Download
              </button>
              
            )}
            content={() => demandPrint.current}
          />
      </div>
          <div ref={demandPrint}  className="previewTableContent"> 
          <div 
                className="previewInvoice"  
                style={{  
                margin: '10px',
                display: "flex",
                padding: "30px",
                paddingBottom: "0px",
                flexDirection: "column",
                alignItems: "center",
                background: "white",
                width: "100%",
                height: "100%"
                }} 
                >
                <PrintTemplate 
                  applicationId={previewDemand?.applicationId} 
                  date={previewDemand?.date} 
                  subject={previewDemand?.subject} 
                  description={previewDemand?.description} 
                  demandList={previewDemand?.products} />
       
       
          </div>
          </div>
        </div>
        </div>
    </>
  );
}

export const searchDemandConsolidatednvoiceData = async (
  data,
  // productType,
  name,
  company,
  salesStartingDate,
  salesEndingDate
) => {
  let Filtered = [];
  console.log(data);
  console.log(name);
  // console.log(Products?.products);
  // console.log(productCompany);

  Filtered = data?.filter((product) => {
    console.log(product)
    if (
      company &&
      !product?.products?.company
        .toString()
        .toLowerCase()
        .includes(company.toString().toLowerCase())
    ) {
      return false;
    }
    if (
      name &&
      !product?.products?.name.toString().toLowerCase().includes(name.toString().toLowerCase())
    ) {
      return false;
    }

    console.log("HIHFE");
    return true;
  });
  console.log(Filtered);
  return Filtered;
};

import React, { useState, useEffect } from "react";
import MainDash from "../MainDash/MainDash"
import RightSide from "../RigtSide/RightSide";
import { useDispatch } from "react-redux";
import { getDashboardDataRecord } from "../../../actions/dashboardAction";
const DashBoard = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDashboardDataRecord())
  },[]);
  return (
    <>
    {/* <MetaData title="~~Dashboard" /> */}
    <div className={`Dashboard`}>
      <div className="dashboardSecondContainer">
        <MainDash/>
        {/* <RightSide /> */}
      </div>
    </div>
    
    </>
  );
};

export default DashBoard;

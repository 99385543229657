import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useParams, useNavigate } from "react-router-dom";
import { RxUpdate } from "react-icons/rx";
import { MdAssignmentAdd } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import ToggleButton from 'react-toggle-button'
import { FiPrinter } from "react-icons/fi";
import { MdPreview } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineUpdate } from "react-icons/md";
// import { useTranslation, initReactI18next } from "react-i18next";
// import { tableState } from "./tableContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { FaBarcode } from "react-icons/fa6";
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { State } from "../demand/DemandContext/context";
import { Audio } from 'react-loader-spinner'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import { RiPassPendingFill } from "react-icons/ri";
import TablePagination from "@mui/material/TablePagination";
import QrCodeIcon from "@mui/icons-material/QrCode";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import InventoryIcon from "@mui/icons-material/Inventory";
import Switch from 'react-switch';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
const TableComponentId = ({
  data,
  columns,
  actions,
  linkk,
  link2,
  actionUpdate,
  action1,
  action3,
  action4,
  deleteShop,
  pendings,
  onRowClick,
  handleReject,
  handleAssign,
  handleEdit,
  viewDetail,
  viewTaskDetail,
  previewDemand,
  handleReceive,
  signatureToggle,
  userStatus,
  deleteItem,
  deleteDemandItem,
  viewDemandDetail,
  editUser,
  roleDropdown,
  approvedUser,
  assignTask,
  task_id,
  handleProductTypeRole,
  assignProductType,
  assignProductTypeRole,
  update
}) => {
  let lengthOfData = columns?.length;
  if (actions) {
    lengthOfData = lengthOfData;
  }
  const {demandList} = useContext(State)
  const [rowCount, setRowCount] = useState()
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const { allTask, loading, message, roleTasks } = useSelector((state) => state.role);
  const {updateRoleTasks, updateRoleTasksLoading} = useSelector((state)=> state.updateRoleTasks)
  const {  currentDemand } = useSelector((state) => state.allDemand);
  const {  requestData, error } = useSelector((state) => state.requests);
  const { user } = useSelector((state) => state.userData);
  const { allRole } = useSelector((state) => state.role);
  
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedColumn) {
      // If the clicked column is already sorted, toggle the direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the sorted column with ascending direction
      setSortedColumn(field);
      setSortDirection("asc");
    }
  };

  // const { setShowModaal } = useContext(Statee);
  let currentIndex = 0;
  useEffect(() => {
    setPage(0)
    setRowCount(data?.length);
  }, [data]);


  const renderCellValue = (item, field) => {
    const fieldKeys = field.split(".");

    return fieldKeys?.reduce((obj, key) => (obj ? obj[key] : ""), item);
  };


  const sortedData = data?.slice().sort((a, b) => {
    if (!sortedColumn) return 0;

    const sortOrder = sortDirection === "asc" ? 1 : -1;
    const aField = renderCellValue(a, sortedColumn);
    const bField = renderCellValue(b, sortedColumn);

    if (aField < bField) return -1 * sortOrder;
    if (aField > bField) return 1 * sortOrder;
    return 0;
  });

  const formatNumber = (num, format) => {
    if(format === "Phone No")
      {
        return num
      }else{
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
  };
  

  const getColumnValue = (user, field, format, render, addedText) => 
    {
      if (format === "date" || format === "time" || format === "bool") 
        {
          return renderDateValue(user, field, format);
        } 
          else 
            if (field.includes(".")) {
                // Handle nested fields
              const nestedFields = field.split(".");
              let value = user;
              for (const nestedField of nestedFields) 
              {
                if (value && value.hasOwnProperty(nestedField)) 
                {
                  value = value[nestedField];
                } 
                  else 
                    {
                      value = ""; // Handle the case where a nested field is missing
                      break;
                    }
              }
      return value;
    }  else
          if(field === addedText &&user.hasOwnProperty(field))
            {
              return  `PMAS-AAUR/UIIT/${user[field]}`
            }else 
            {
              return (typeof user[field] === 'number') ? formatNumber(user[field], format) : user[field];
            }
  };

  // Function to render date values using moment.js
  const renderDateValue = (item, field, format) => {
    const value = renderCellValue(item, field);
    // console.log(value);

    //for converting bool value into string ..just for dispalying into table
    if (format === "bool") {
      return value && value.toLocaleString();
    }

    if (format === "time") {
      // console.log("time");
      return value ? new Date(value).toLocaleTimeString() : "";
    }
    if (format === "date") {
      // console.log("date");
      return value ? new Date(value).toLocaleDateString("en-GB") : "";
    }

    return value;
  };

  const noop = () => {};

  const handleRowClick = onRowClick || noop;
  // const classes = useStyles();

  return (
    <>
      {/* <div className="tableComponent"> */}
      <TableContainer
        component={Paper}
        className="heightSetting"
        // sx={{  zIndex: "1" }}
      >
        {sortedData && sortedData?.length > 0 ? (
          <Table
            sx={{ minWidth: 650, zIndex: "1" }}
            size="small"
            ariel-label="a dense table"
            // className={classes.cell}
            className={`customTable`}
            stickyHeader
          >
            <TableHead>
              <TableRow className="tableHead" align={"center"}>
                <TableCell
                  className="tableHead"
                  align={"center"}
                  // style={{ backgroundColor: "#ECECEC", zIndex: "1" }}
                >
                  {"Sr.No"}
                </TableCell>
                {columns?.map((column) => (
                  <TableCell
                    className="tableHead"
                    align={"center"}
                    // style={{ backgroundColor: "#ECECEC", zIndex: "1" }}
                    onClick={() => handleSort(column.field)}
                  >
                    {column.label}
                    {sortedColumn === column.field && (
                      <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell
                    className="tableHead"
                    align={"center"}
                    // style={{ backgroundColor: "#ECECEC" }}
                  >
                    {"Actions"}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow
                    key={index}
                    className={` ${index % 2 === 0 ? "evenRow" : "oddRow"} `}
                    onClick={() => handleRowClick(item)}
                  >
                    <TableCell
                      align={"center"}
                      className={index % 2 === 0 ? "evenRow" : "oddRow"}
                    >
                      {++currentIndex}
                    </TableCell>
                    {columns?.map((column) => (
                      <TableCell
                        align="center"
                        className="border-Right"
                        key={column.field}
                      >
                        {column.render
                          ? column.render(item)
                          : getColumnValue(
                              item,
                              column.field,
                              column.format,
                              column.render,
                              column.addedText
                            )}
                        {/* {columnn.render ? columnn.render(item) : item[columnn.field]} */}
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell
                        align={"center"}
                        className={` column-borde`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {actions?.map((action) => {
                          if (action.label === action3) {
                            return (
                              <Tooltip title="Delete Record" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                onClick={() => actions[0].handler(item._id)}
                              >
                                <DeleteIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                          // console.log(item)
                          if(item?.status === "waiting")
                          {
                            if (action.label === handleReject) {
                            return (
                              <Tooltip title="Reject" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                // onClick={console.log(actions, item._id)}
                                onClick={() => actions[0].handler(item._id)}
                              >
                                <NotInterestedIcon />
                              </Button>
                              </Tooltip>
                            );
                          }

                          if (action.label === handleAssign) {
                           
                            return (
                              <Tooltip title="Assinged" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                onClick={() => actions[1].handler(item)}
                              >
                                <AssignmentTurnedInIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                        }
                        
                        if(item?.status === "assigned")
                        {
                          if (action.label === handleEdit) {
                           
                            return (
                              <Tooltip title="Edit" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                disabled={requestData?.status === "Pending Approval" || requestData?.status === "Completed" }
                                // onClick={console.log(actions, item._id)}
                                onClick={() => actions[2].handler(item)}
                              >
                                <DriveFileRenameOutlineIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                        }

                        if (action.label === roleDropdown) {
                        
                          return (
                            <Tooltip title="View Details" arrow>
                          <select
                            className='select-role'
                            onChange={
                              (e) => actions[0].handler(item, e.target.value)
                            }
                          >
                            <option value="">Select role</option>
                            {allRole.map((role) => (
                              <option key={role._id} value={role._id}>{role.name}</option>
                              ))}
                          </select>
                            </Tooltip>
                          );
                        }
                        if (action.label === approvedUser) {
                        
                          return (
                            <Tooltip title="Approve User" arrow>
                            <Button
                              key={action.label}
                              disabled = {item?.status === "Approved"}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                            {/* {"Approved"} <MdOutlineUpdate /> */}
                            <RiPassPendingFill />
                            </Button>
                            </Tooltip>
                          );
                        }

                        if (action.label === assignTask) {
                          // console.log(item)
                          return (
                            <>
                            {/* {
                              updateRoleTasksLoading && item?._id === task_id  ? (<>
                              <ClipLoader
        // color={color}
        // loading={loading}
        // cssOverride={override}
                              size={15}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              />
                              </>) : ( */}
                                <Tooltip title="Assigned Task" arrow>
                                <input
                                  type="checkbox"
                                  defaultChecked={item.status ? true : false}
                                  // checked={item.status}
                                  onChange={(e) =>actions[0].handler(item, e) }
                                />
                                </Tooltip>
                                {/* )
                            } */}
                          
                            </>
                           
                          );
                        }

                        if (action.label === handleProductTypeRole) {
                          console.log(item)
                          return (
                            <Tooltip title="Assigned Task" arrow>
                            <input
                              type="checkbox"
                              // checked={!item._id}
                              onChange={(e) =>actions[0].handler(item, e) }
                            />
                            </Tooltip>
                          );
                        }
                        if (action.label === assignProductTypeRole) {
                          console.log(item)
                          return (
                            <Tooltip title="Assigned Task" arrow>
                            <input
                              type="checkbox"
                              checked={item.status}
                              onChange={(e) =>actions[0].handler(item, e) }
                            />
                            </Tooltip>
                          );
                        }
                        if (action.label === assignProductType) {
                          console.log(item)
                          return (
                            <Tooltip title="Assign Product Category" arrow>
                              <Button
                              key={action.label}
                              // disabled = {item?.status === "Approved"}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                             <MdAssignmentAdd />
                            </Button>
                            </Tooltip>
                          );
                        }
                        if (action.label === viewDetail) {
                        
                          return (
                            <Tooltip title="View Details" arrow>
                            <Button
                              key={action.label}
                              disabled = {item?.status === "Approved"}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                             <MdOutlineUpdate />
                            </Button>
                            </Tooltip>
                          );
                        }

                        if (action.label === viewTaskDetail) {
                        
                          return (
                            <Tooltip title="Assign Task" arrow>
                            <Button
                              key={action.label}
                              disabled = {item?.status === "Approved"}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                             <MdOutlineUpdate />
                            </Button>
                            </Tooltip>
                          );
                        }
                        if (action.label === viewDemandDetail) {
                          console.log(item)
                          let isItemInList = false;
                          demandList?.map((list) => {
                            if (list?._id === item?._id ) {
                              isItemInList = true;
                            }
                          });


                          if(isItemInList){
                              return (
                                <Button
                                  key={action.labeladded}
                                  onClick={() => actions[0].handler(item)}
                                  disabled
                                >
                                  {"Added"}
                                </Button>
                              );
                            }else{
                                 return (
                                <Button
                                  key={action.label}
                                  onClick={() => actions[0].handler(item)}
                                  disabled={false}
                                >
                                  <MdOutlineUpdate />
                                </Button>
                              );
                            }
                        }

                        if (action.label === previewDemand) {
                          return (
                            <Tooltip title="Preview" arrow>
                            <Button
                              key={action.label}
                              // disabled = {item?.status === "Approved"}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[1].handler(item)}
                            >
                              <MdPreview />
                            </Button>
                            </Tooltip>
                          );
                        }

                        if (action.label === handleReceive) {
                          return (
                            <Tooltip title="Received" arrow>
                            <Button
                              key={action.label}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              disabled={item?.status === "received"}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                             <IoEyeSharp />
                            </Button>
                            </Tooltip>
                          );
                        }
                        if (action.label === signatureToggle) {
                          return (
                            <Tooltip title="Received" arrow>
                              {/* <ToggleButton
                                value={ item.status || false }
                                onToggle={() => actions[0].handler(item)}
                                style={{backgroundColor: "white"}}
                              /> */}
                              
                              <Switch
                                  onChange={() => actions[0].handler(item)}
                                  checked={item.status}
                                  onColor="#007bff"
                                  offColor="#ff7b7b"
                                  border='none'
                                  uncheckedIcon={
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        fontSize: 10,  
                                        color: 'white',
                                        paddingRight: 2,
                                      }}
                                    >
                                      Inactive
                                    </div>
                                  }
                                  checkedIcon={
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        fontSize: 10,  // Smaller font size
                                        color: 'white',
                                        paddingRight: 2,
                                      }}
                                    >
                                      Active
                                    </div>
                                  }
                                  height={22} // Adjust height
                                  width={60}  // Increased width to fit text
                                  handleDiameter={20} // Handle size to match the new height
                                /> 
                            </Tooltip>
                          );
                        }

                        if (action.label === deleteItem) {
                          return (
                            <Tooltip title="Delete" arrow>
                                    <Button
                              key={action.label}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(item)}
                            >
                             <DeleteSweepIcon />
                            </Button>
                            </Tooltip>
                          );
                        }
                        if (action.label === deleteDemandItem) {
                          return (
                            <Tooltip title="Delete" arrow>
                                    <Button
                              key={action.label}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[0].handler(index)}
                            >
                             <DeleteSweepIcon />
                            </Button>
                            </Tooltip>
                          );
                        }
                        if (action.label === editUser) {
                          return (
                            <Tooltip title="Edit" arrow>
                                    <Button
                              key={action.label}
                              style={{
                                backgroundColor: "#transparent",
                                marginRight: "5px",
                              }}
                              // onClick={console.log(actions, item._id)}
                              onClick={() => actions[1].handler(item)}
                            >
                             <RxUpdate />
                            </Button>
                            </Tooltip>
                          );
                        }
                        console.log(item)
                        if (action.label === userStatus) 
                        {
                          if (item?.role_id?.name === 'admin') 
                            {
                              return (
                                item._id !== user._id && item.role_id.name !== 'SuperAdmin' && (
                                    <Switch
                                    onChange={() => actions[0].handler(item)}
                                    checked={item?.status === "Active"}
                                    onColor="#007bff"
                                    offColor="#ff7b7b"
                                    border='none'
                                    uncheckedIcon={
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%',
                                          fontSize: 10,  
                                          color: 'white',
                                          paddingRight: 2,
                                        }}
                                      >
                                        Inactive
                                      </div>
                                    }
                                    checkedIcon={
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%',
                                          fontSize: 10,  // Smaller font size
                                          color: 'white',
                                          paddingRight: 2,
                                        }}
                                      >
                                        Active
                                      </div>
                                    }
                                    height={22} // Adjust height
                                    width={60}  // Increased width to fit text
                                    handleDiameter={20} // Handle size to match the new height
                                        //  onChange={() => actions[0].handler(item)}
                                        // checked={item.status}
                                        // onColor="#0033A0"
                                        // uncheckedIcon={
                                        //   <div
                                        //     style={{
                                        //       display: 'flex',
                                        //       justifyContent: 'center',
                                        //       alignItems: 'center',
                                        //       height: '100%',
                                        //       fontSize: 10,  
                                        //       color: 'white',
                                        //       paddingRight: 2,
                                        //     }}
                                        //   >
                                        //     Inactive
                                        //   </div>
                                        // }
                                        // checkedIcon={
                                        //   <div
                                        //     style={{
                                        //       display: 'flex',
                                        //       justifyContent: 'center',
                                        //       alignItems: 'center',
                                        //       height: '100%',
                                        //       fontSize: 10,  // Smaller font size
                                        //       color: 'white',
                                        //       paddingRight: 2,
                                        //     }}
                                        //   >
                                        //     Active
                                        //   </div>
                                        // }
                                        // height={22} // Adjust height
                                        // width={60}  // Increased width to fit text
                                        // handleDiameter={20} // Handle size to match the new height
                                    />
                                )
                            );
                        } else {
                          console.log('ahfeli')
                            return (
                                    <Switch
                                        //   onChange={() => actions[0].handler(item)}
                                        // checked={item.status}
                                        // onColor="#0033A0"
                                        // disabled={item._id == user._id || item.role_id.name == 'SuperAdmin'}
                                        onChange={() => actions[0].handler(item)}
                                        checked={item.status === "Active"}
                                        onColor="#007bff"
                                        offColor="#ff7b7b"
                                        border='none'
                                        disabled={item._id == user._id || item.role_id.name == 'SuperAdmin'}
                                        uncheckedIcon={
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: '100%',
                                              fontSize: 10,  
                                              color: 'white',
                                              paddingRight: 2,
                                            }}
                                          >
                                            Inactive
                                          </div>
                                        }
                                        checkedIcon={
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: '100%',
                                              fontSize: 10,  // Smaller font size
                                              color: 'white',
                                              paddingRight: 2,
                                            }}
                                          >
                                            Active
                                          </div>
                                        }
                                        height={22} // Adjust height
                                        width={60}  // Increased width to fit text
                                        handleDiameter={20} // Handle size to match the new height
                                    />
                            );
                        }
                    }
                        
                       
                          if (action.label === deleteShop) {
                           
                            return (
                              <Tooltip title="Delete Record" arrow>
                              <Button
                                key={action.label}
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                // onClick={console.log(actions, item._id)}
                                onClick={() => actions[0].handler(item._id)}
                              >
                                <DeleteIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                          if (action.label === action4 ) {
                            return (
                              <Tooltip title="Delete Record" arrow>
                              <Button
                                key={action.label}
                                // variant="contained"
                                style={{
                                  backgroundColor: "#transparent",
                                  marginRight: "5px",
                                }}
                                // color={action.color}
                                onClick={() => actions[0].handler(item._id)}
                                // className="Hello3"
                              >
                                {/* <ArrowForwardIcon /> */}
                                {/* {t("delete")}&nbsp; */}
                                <DeleteIcon />
                              </Button>
                              </Tooltip>
                            );
                          }
                          if (action.label === pendings) {
                            return (
                              <Tooltip title="Continue" arrow>
                              <Button
                                key={action.label}
                               
                                // color={action.color}
                                onClick={() => actions[1].handler(item._id)}
                                // className="Hello3"
                              >
                                <ArrowForwardIcon />
                                {/* {t("pendings")}&nbsp; */}
                              </Button>
                              </Tooltip>
                            );
                          }
                          if (action.label === "Preview") {
                            return (
                              <Button
                                key={action.label}
                                // variant="contained"
                                style={{zIndex: "0" }}
                                // color={action.color}
                                onClick={() => actions[0].handler(item.id)}
                                // className="Hello3"
                              >
                                {/* {action.label}&nbsp; */}
                                <PreviewIcon />
                              </Button>
                            );
                          }
                          if (action.label === "InvoicePreview") {
                            return (
                              <Button
                                key={action.label}
                                // variant="contained"
                                style={{zIndex: "0" }}
                                // color={action.color}
                                onClick={() =>
                                  actions[0].handler(item.invoiceNumber)
                                }
                                // className="Hello3"
                              >
                                {/* {action.label}&nbsp; */}
                                <PreviewIcon />
                              </Button>
                            );
                          }
                          if (action.label === "delete") {
                            return (
                              <Button
                                key={action.label}
                                onClick={() => {
                                  // setShowModal(true);
                                  actions[0].handler(item.id);
                                }}
                              >
                                <AiOutlineDelete className="text-red-500 font-bold text-xl" />
                              </Button>
                            );
                          }
                          if (action.label === "deleteee") {
                            return (
                              <Button
                                key={action.label}
                                onClick={() => {
                                  // setShowModal(true);
                                  actions[0].handler(index);
                                }}
                              >
                                <AiOutlineDelete className="text-red-500 font-bold text-xl" />
                              </Button>
                            );
                          }
                          if (action.label === "dlete") {
                            return (
                              <Button
                                key={action.label}
                                onClick={() => {
                                  // setShowModaal(true);
                                  actions[0].handler(item.id);
                                }}
                              >
                                <AiOutlineDelete className="text-red-500 font-bold text-xl" />
                              </Button>
                            );
                          }
                          if (action.label === "dlette") {
                            return (
                              <Button
                                key={action.label}
                                onClick={() => {
                                  // setShowModall(true);
                                  actions[0].handler(item.id);
                                }}
                              >
                                <AiOutlineDelete className="text-red-500 font-bold text-xl" />
                              </Button>
                            );
                          }
                          if (
                            action.label === update
                          ) {
                            return (
                              <Tooltip title="Update Record" arrow>
                              <Button
                                key={action.label}
                                // variant="contained"
                                style={{ backgroundColor: "#transparent" }}
                                // color={"warning"}
                                onClick={() => {
                                  actions[0].handler(item);
                                }}
                                // className="Hello3"
                              >
                                {/* {t("update")}&nbsp;&nbsp; */}
                                <UpdateIcon />
                              </Button></Tooltip>
                            );
                          }
                          if (action.label === "Purchase") {                  
                            return (
                                <Button
                                  key={action.label}
                                  onClick={() => actions[0].handler(item._id)}
                                  disabled={false}
                                >
                                  <InventoryIcon />
                                </Button>
                              );
                          }
                          if(action.label === "request")
                          {

                          }
                          // if (action.label === "Sale") 
                          // {
                          //   let itemQuantityAvailable = true
                          //   if (item.productQuantity < 1) {
                          //     itemQuantityAvailable = false
                          //   }
                          //   let isItemInList = false;
                          //   list?.map((list) => {
                          //     if (list?.quantityidset === item?.product?._id && list?.productColor === item?.colorId?._id) {
                          //       isItemInList = true;
                          //     }
                          //   });

                          //   if(!itemQuantityAvailable)
                          //   {
                          //     return (
                          //       <Button
                          //         key={action.labeladded}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={true}
                          //       >
                          //         <ClosedCaptionDisabledIcon />
                          //       </Button>
                          //     );
                          //   }else
                          //   if(isItemInList){
                          //     return (
                          //       <Button
                          //         key={action.labeladded}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={true}
                          //       >
                          //         {"Added"}<ClosedCaptionDisabledIcon />
                          //       </Button>
                          //     );
                          //   }else{
                          //        return (
                          //       <Button
                          //         key={action.label}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={false}
                          //       >
                          //         <LoyaltyIcon />
                          //       </Button>
                          //     );
                          //   }
                          // }
                          // if (action.label === "transfer") {
                          //   console.log(listTransfer)
                          //   console.log(item)
                          //   let itemQuantityAvailable = true
                          //   if (item.productQuantity < 1) {
                          //     itemQuantityAvailable = false
                          //   }
                          //   let isItemInList = false;
                          //   listTransfer?.map((list) => {
                          //     if (list?.quantityidset === item?.product?._id && list?.productColor === item?.colorId?._id) {
                          //       isItemInList = true;
                          //     }
                          //   });

                          //   if(!itemQuantityAvailable)
                          //   {
                          //     return (
                          //       <Button
                          //         key={action.labeladded}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={true}
                          //       >
                          //         <ClosedCaptionDisabledIcon />
                          //       </Button>
                          //     );
                          //   }else
                          //   if(isItemInList){
                          //     return (
                          //       <Button
                          //         key={action.labeladded}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={true}
                          //       >
                          //         {"Added"}<ClosedCaptionDisabledIcon />
                          //       </Button>
                          //     );
                          //   }else{
                          //        return (
                          //       <Button
                          //         key={action.label}
                          //         onClick={() => actions[0].handler(item._id)}
                          //         disabled={false}
                          //       >
                          //         <LoyaltyIcon />
                          //       </Button>
                          //     );
                          //   }
                          // }
                          // if (action.label === action1) {
                          //   return (
                          //     <Tooltip title="View Barcode" arrow>
                          //     <Button
                          //       key={action.label}
                          //       style={{
                          //         backgroundColor: "#transparent",
                          //         marginLeft: "5px",
                          //       }}
                          //       onClick={() => {
                          //         navigate(`/${link2}/${item._id}`);
                          //       }}
                                
                          //     >
                          //        <FaBarcode /> 
                          //     </Button>
                          //     </Tooltip>
                          //   );
                          // }
                          // //Just For Change Permission for Super Admin
                          // if (action.label === "change Permission") {
                          //   return (
                          //     <Tooltip title="Change Permission" arrow>
                          //     <Button
                          //       key={action.label}
                          //       // variant="contained"
                          //       style={{
                          //         // backgroundColor: "#07B235",
                          //         marginLeft: "5px",
                          //       }}
                              
                          //       //  color={"success"}
                          //       onClick={() => {
                          //         localStorage.setItem(
                          //           "userpermission",
                          //           JSON.stringify(item._id)
                          //         );
                          //         localStorage.setItem(
                          //           "roleNameForPermissions",
                          //           JSON.stringify(item.roleName)
                          //         );
                          //         navigate("/rolesAssign");
                          //       }}
                          //       // className="Hello3"
                          //     >
                          //       {/* {t("changePermissions")}&nbsp; */}
                          //       <LockOpenIcon />
                          //     </Button>
                          //     </Tooltip>
                          //   );
                          // }
                          if (
                            action.label === actionUpdate
                          ) {
                            return (
                              <Tooltip title="Update Record" arrow>
                              <Button
                                key={action.label}
                                // variant="contained"
                                style={{ backgroundColor: "#transparent" }}
                                // color={"warning"}
                                onClick={() => {
                                  navigate(`/${linkk}/${item._id}`);
                                }}
                                // className="Hello3"
                              >
                                {/* {t("update")}&nbsp;&nbsp; */}
                                <UpdateIcon />
                              </Button></Tooltip>
                            );
                          }
                       
                          if (action.label === "PreviewPaidInvoice") {
                            return (
                              <Button
                                key={action.label}
                                // variant="contained"
                                // style={{ backgroundColor: "#E5B000" }}
                                // color={"warning"}
                                onClick={() => {
                                  navigate(`/${linkk}/${item._id}`);
                                }}
                                // className="Hello3"
                              >
                                {/* {t("Prewiew")}&nbsp;&nbsp; */}
                                <UpdateIcon  />
                              </Button>
                            );
                          }
                        })}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div>No record found.</div>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={sortedData?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* </div> */}
    </>
  );
};

export default TableComponentId;

import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import TableComponentId from '../../Table Component/tableComponent';
import { getAllRole, getSpecificRolesData } from '../../../actions/roleAction';

const TypeRolesModal = ({ typeAssigned, openRoleModal, setOpenRoleModal, setAssignToRole, skipButton,setSkipButton, assignToRole, handleSubmitRecord }) => {
    const dispatch = useDispatch();
    const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
    const [product, setProduct] = useState([]);
    const navigate = useNavigate()
    const handleProductTypeRole = "ProductTypeAssignedTo"
    const { loading, message, error, allRole } = useSelector(
        (state) => state.role
      );
    const {user} = useSelector((state)=> state.userData)

    useEffect(()=>{
        // 
        typeAssigned = []
        dispatch(getSpecificRolesData());
        // if(user?.role_id?.name === "SuperAdmin")
        // {
        //     dispatch(getAllRole())
        // }else{
        //     dispatch(getSpecificRolesData());
        // }
      }, [user])
    

    const handleRecord = (data, e) => {
        console.log(data, e.target.checked)
        console.log(assignToRole)
        typeAssigned.push({
             _id: data._id,
             status: e.target.checked
        })
        console.log(typeAssigned)
        setAssignToRole(typeAssigned)
    };

    const handleApply = () => {
        setAssignToRole(typeAssigned)
        setOpenRoleModal(!openRoleModal)
    }

    const clickCloseIcon = () => {
        typeAssigned=[]
        setAssignToRole([])
        setOpenRoleModal(!openRoleModal);
    };


    const columns = [
        { field: "name", label: "Role Name" },
      ];

      const actions = [
        {
          label: "ProductTypeAssignedTo",
          color: "yellow",
          handler: (itemId, e) => handleRecord(itemId, e),
        },
   
      ];

    return (
        <Fragment>
            {loading ? (
                <div className="modal">
                <div className="modal-content">
                    <Loader />
                    </div></div>
            ) : (<>
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={clickCloseIcon}><CloseIcon /></button>
                                </div>
                            <div className="contentt-box">
                                <div className="heading-container">
                                    <h3>{"Select Role For Category"}</h3>
                                </div>
                            </div>
                            <div className='search-box'>

                            </div>
                            <div className="table-container">
                                {/* <div className="View_Modal_Table_wrapper"> */}
                                    <></>
                                    <TableComponentId
                                        data={allRole}
                                        columns={columns}
                                        actions={actions}
                                        handleProductTypeRole={handleProductTypeRole}
                                    />
                                 
                                {/* </div> */}
                            </div>
                            <div className='form'>
                            <div className='buttonRow'>
                            <button
                                className='button-back'
                                onClick={()=>{
                                   
                                    handleSubmitRecord("Skip")
                                }
                                   }
                            >
                                Skip
                            </button>
                            <button
                                className='button-add-product'
                                onClick={()=>{
                                    setSkipButton(false)
                                    handleSubmitRecord("submit")}}
                                
                            >
                                Apply
                            </button>
                        </div>
                            </div>
                            </div>
                            </div>
            </>)}
           
  
        </Fragment>
    );
};

export default TypeRolesModal;

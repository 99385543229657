import React, { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { getDemandConsolidatedRecord } from "../../../actions/demandAction";
import TableComponentId from "../../Table Component/tableComponent";
import PrintLaserTable from "../../Table Component/printLaserTable";
import { searchDemandConsolidatednvoiceData } from "../../Search Component/DemandReportSearch";
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from "react-to-print";
let name = "";
let companyProduct = "";
const ConsolidatedDemandReport = () => {
  const dispatch = useDispatch()
  const [isCalled, setIsCalled] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [valuesChanging, setValuesChanging] = useState(true)
  const [returnData, setReturnData] = useState()
  const [salesConsolidatedPermission, setSalesConsolidatedPermission] =
    useState(false);
  const consolidatedSalesInvoice = useRef();

  const [salesProductCodeDropDown, setSalesProductCodeDropDown] = useState("");
  const [salesProductCompanyDropDown, setsalesProductCompanyDropDown] =
    useState("");
  const [demandStartDateDropDown, setDemandStartDateDropDown] = useState(null);
  const [demandEndDateDropDown, setDemandEndDateDropDown] = useState(null);
  // const { shop } = useSelector((state) => state.shop);
  //printerOption
  const [selectedPrinter, setSelectedPrinter] = useState();
  const [loadingPrintData, setLoadingPrintData] = useState(false)
  const [tableRecord, setTableRecord] = useState()

  const {demandConsolidateRecord, demandConsolidateRecordLoading } = useSelector((state)=> state.demandConsolidateRecord) 

 

  ////////======================================================///////////////
  /////======== Handle All Daily, weekly, Monthly, Yearly Data ======////////
  ///////======================================================///////////////

  const [selectedOption, setSelectedOption] = useState();

  const handleBeforePrint = () => {
    console.log('ajfei')
    setLoadingPrintData(true);
  };

  const handleAfterPrint = () => {
    setLoadingPrintData(false);
  };

  
  useEffect(() => {
     dispatch(getDemandConsolidatedRecord("last3Months"))
  }, []);

  useEffect(() => {
    if(!demandConsolidateRecordLoading ) 
      {
        console.log("called")
        setTableData(demandConsolidateRecord?.demandData);
        setValuesChanging(false)
      }
  },[demandConsolidateRecordLoading, demandConsolidateRecord])

  const handleClearData = () =>{
    setValuesChanging(true)
    companyProduct=''
    name=''
    setSalesProductCodeDropDown("");
    setsalesProductCompanyDropDown("");
    setDemandStartDateDropDown("");
    setDemandEndDateDropDown("");
    setSelectedOption("");
    setIsDisabled(false);
     dispatch(getDemandConsolidatedRecord("last3Months"))
  }


  //Function to set Starting Date
  const handleDateSelectChange = (date) => {
    if(demandEndDateDropDown){
      dispatch(getDemandConsolidatedRecord(date, demandEndDateDropDown))
    }
    
    setDemandStartDateDropDown(date);
  };


//Function to set Ending Date
const handleSelectEndDateChange =async (date) => {
  setDemandEndDateDropDown(date);
      dispatch(getDemandConsolidatedRecord(demandStartDateDropDown, date))
         setValuesChanging(true)
};


  // Handle the dropdown selection
  const handleDropdownChange =async ( value ) => {
    console.log(value)
    setSelectedOption(value);
    setValuesChanging(true)
   dispatch(getDemandConsolidatedRecord(value))

  };



  

  //Function to search element
  const handleSearch = async (name, company) => {
    console.log(tableRecord)
    setValuesChanging(true)
    const finalDataForTable = await searchDemandConsolidatednvoiceData(
      demandConsolidateRecord?.demandData,
      name,
      company,
    );
    setTableData(finalDataForTable);
    setValuesChanging(false)
  };
  const columns = [
    { field: "products.name", label: "Name" },
    { field: "products.specifications", label: "Specification" },
    { field: "products.type", label: "Type" },
    { field: "products.company", label: "Company" },
    { field: "products.quantity", label: "Requested Quantity" },
    { field: "products.received_quantity", label: "Received Quantity" },
    { field: "products.status", label: "Status" },
    { field: "products.dateWithProduct", label: "Invoice Date" },
  ];


  return (
    <>
      {/* <MetaData title="QE ~~SaleInvoice" /> */}
      <div className={`Demand`}>
      <div className="secondContainer">
          <>
            <div className="contentt-box">
              <div className="heading-container">
                <h3>{"Consolidated Demand Invoice"}</h3>
              </div>
            </div>
            <div className="search-box">
              <select
                value={selectedOption}
                onChange={(e)=> handleDropdownChange(e.target.value)}
              >
                <option value="">Select Option</option>
                <option value="today">Today</option>
                <option value="thisWeek">This Week</option>
                <option value="thisMonth">This Month</option>
                <option value="last3Months">Last Three Months</option>
                <option value="lastYear">Last Year</option>
                <option value="All">All</option>
              </select>
             
              <input
                type="text"
                name="productCode"
                placeholder={"Enter Name Of Product"}
                autoComplete="off"
                value={name}
                onChange={(e) => {
                  name = e.target.value;
                  handleSearch(name, companyProduct);
                }}
              />
              <input
                type="text"
                name="productCode"
                placeholder={"Enter Company"}
                autoComplete="off"
                value={companyProduct}
                onChange={(e) => {
                  companyProduct = e.target.value;
                  handleSearch(name, companyProduct);
                }}
              />
              <DatePicker
                selected={demandStartDateDropDown}
                onChange={handleDateSelectChange}
                placeholderText={"Starting Date"}
                dateFormat="dd/MM/yyyy"
                // className="datePicker"
              />
              <DatePicker
                selected={demandEndDateDropDown}
                onChange={handleSelectEndDateChange}
                
                placeholderText={"Ending Date"}
                dateFormat="dd/MM/yyyy"
                // className="datePicker"
              />
              <button
                className="consolidatedClearButton"
                onClick={handleClearData}
              >
                {"clear"}&nbsp;&nbsp;{<ClearAllIcon />}
              </button>
              <ReactToPrint
                trigger={() => (
                  <button className="consolidatedClearButton" onClick={handleBeforePrint}>
                    Print&nbsp;&nbsp;
                    <PrintIcon />
                  </button>
                )}
                content={() => consolidatedSalesInvoice.current}
                onBeforeGetContent={handleBeforePrint}
              />
            </div>
            <div className="table-container">
              {
                !valuesChanging && 
                (<>
                  <TableComponentId 
                    data={tableData}
                    columns={columns} />
                </>)
              }
                  
                  <div style={{ display: "none" }}>
                <div
                  ref={consolidatedSalesInvoice}
                  //
                  style={{
                    padding: "5px",
                    display: "flex",
                    paddingBottom: "0px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                   <PrintLaserTable
                          data={tableData}
                          columns={columns}
                        />
                </div>
              </div>
             
            </div>
            
          </>
          </div>
      </div>
    </>

  );
};

export default ConsolidatedDemandReport;

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { getRoleTasks, updateRoleTask, clearError, updateAssignTaskData } from '../../../actions/roleAction';
import Loader from '../../Loader/Loader';
import TablePagination from '@mui/material/TablePagination';
import ReactTable from '../../ReactTable'; // Ensure the path is correct
import TableComponentId from '../../Table Component/tableComponent';
import { UPDATE_ASSIGNED_ROLE_TASKS_SUCCESS } from '../../../Redux/constants/roleConstant';

const EditTask = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useAlert();
    const assignTask = "Assign Task"
    // const roleData = location.state ? location.state.roleData : null;
    const [task_id, setTask_id] = useState()
    const { allTask, loading, message, roleTasks, error } = useSelector((state) => state.role);
    const {updateAssignedTask, updateAssignedTaskLoading} = useSelector((state)=> state.updateAssignedTask)
    const {assignedRoleTask, assignedRoleTaskLoading} = useSelector((state)=> state.assignedRoleTask)
    const [searchTask, setSearchTask] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tasks, setTasks] =useState([])

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
        // if (roleData) {
  
        // }
    }, [dispatch, error, alert]);

    useEffect(() => {
        if (message) {
            alert.success(message);
        }
    }, [message]);
    // console.log(tasks);

    const handleChangeCheckbox = (taskId, e) => {
        
        console.log(taskId, e)
        setTask_id(taskId)
        const newStatus = e.target.checked;
        const task = {task_id: taskId, status: newStatus}
        const filterTaskIndex = tasks.findIndex(t => t.task_id === taskId)
        if(filterTaskIndex !== -1)
        {
            console.log(filterTaskIndex)
            tasks.splice(filterTaskIndex, 1)
            console.log(tasks)
        }
        else
        {
            console.log(filterTaskIndex)
            tasks.push(task)
            console.log(tasks)
        }
        // dispatch(updateRoleTask(roleId, taskId, newStatus));
        // dispatch(getRoleTasks(roleId));
        
    };

    const applyChanges = () => {
        console.log(tasks)
        dispatch(updateAssignTaskData(location?.state?.roleData?._id, tasks))
        setTasks([])
        navigate('/roleTaskEdit', { state: location?.state?.roleData });
    }

    useEffect(()=>{
        console.log(updateAssignedTask)
        if(updateAssignedTask?.message==="Tasks updated successfully" && !updateAssignedTaskLoading)
        {
            alert.success(updateAssignedTask?.message)
            dispatch({ type: UPDATE_ASSIGNED_ROLE_TASKS_SUCCESS, payload: [] });
            navigate('/editTask');
            // dispatch(getRoleTasks(location.state.roleData._id));
        }
    }, [updateAssignedTaskLoading, updateAssignedTask])




    const columns = [
        { field: "task_id.name", label: "Task" },
        { field: "task_id.type", label: "Category" },
        { field: "task_id.description", label: "Description" },
      ];

      const actions = [
        {
          label: "Assign Task",
          color: "yellow",
          handler: (itemId, e) => handleChangeCheckbox(itemId?._id, e),
        },
      ];   
    return (
        <Fragment>

        <div className={`Task`}>
        <div className="secondContainer">
            <div className="contentt-box">
                <div className="heading-container">
                    <h3>{`${location?.state?.roleData?.name} Tasks`}</h3>
                    <h5>
                        <span className="total-records">
                            {"Total Records"}&nbsp;&nbsp;
                            <EventAvailableIcon fontSize="small" />
                        </span>
                        {/* <span className="rowCount">{rowCount}</span> */}
                    </h5>
                </div>
                <div className="excelDiv">
                <button  onClick={() => navigate('/roleTaskEdit')}>Back</button>
               {tasks?.length > 0 && <button  onClick={applyChanges}>Apply Changes</button>} 
                    {/* {task ? <button onClick={handleAddTaskClick}>Add Task</button>: null} */}
                </div>
            </div>
            <div className="search-box">
            <input
                type="text"
                placeholder="Enter Task Name"
                value={searchTask}
                onChange={(e) => setSearchTask(e.target.value)}
            />
            
            </div>
            <div className="table-container">
                        {assignedRoleTaskLoading ? 
                            (
                                <Loader />
                            ) : 
                            (
                                <TableComponentId data={assignedRoleTask}
                                columns={columns}
                                actions={actions}
                                assignTask={assignTask}
                                task_id={task_id}
                                />
                            )
                        }
                    
            </div>
        </div>
    </div>


        </Fragment>
    );
};

export default EditTask;

import { act } from "react";
import { 
  ADD_DEMAND_DATA,
  UPDATE_DATA, 
  CLEAR_DEMAND_DATA, 
  DEMAND_DETAIL,
   CURRENT_DEMAND, 
   CLEAR_DETAILS,
   GET_ALL_PRODUCT_FROM_LOCATION_REQUEST,
   GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS,
   GET_ALL_PRODUCT_FROM_LOCATION_FAIL,
   GET_ALL_LOCATION_REQUEST,
   GET_ALL_LOCATION_SUCCESS,
   GET_ALL_LOCATION_FAIL,
   GET_ALL_DEMAND_REQUEST,
   GET_ALL_DEMAND_SUCCESS,
   GET_ALL_DEMAND_FAIL,
   POST_DEMAND_QUANTITY_REQUEST,
   POST_DEMAND_QUANTITY_SUCCESS,
   POST_DEMAND_QUANTITY_FAIL,
   UPDATE_DEMAND_STATUS_REQUEST,
   UPDATE_DEMAND_STATUS_SUCCESS,
   UPDATE_DEMAND_STATUS_FAIL,
   GET_ACTIVE_ASSISTANT_DIRECTOR_REQUEST,
   GET_ACTIVE_ASSISTANT_DIRECTOR_SUCCESS,
   GET_ACTIVE_ASSISTANT_DIRECTOR_FAIL,
   GET_DEMAND_BY_ID_REQUEST,
   GET_DEMAND_BY_ID_SUCCESS,
   GET_DEMAND_BY_ID_FAIL,
   CLEAR_MESSAGE,
   CLEAR_ERROR,
   GET_PREVIEW_DEMAND_REQUEST,
   GET_PREVIEW_DEMAND_SUCCESS,
   GET_PREVIEW_DEMAND_FAIL,
   SAVE_DEMAND_REQUEST,
   SAVE_DEMAND_SUCCESS,
   SAVE_DEMAND_FAIL,
   UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_REQUEST,
   UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_SUCCESS,
   UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_FAIL,
   GET_DEMAND_CONSOLIDATED_RECORD_REQUEST,
   GET_DEMAND_CONSOLIDATED_RECORD_SUCCESS,
   GET_DEMAND_CONSOLIDATED_RECORD_FAIL
   } from "../constants/demandConstants";

// counterReducer.js
const initialState = {
  data: [],
  detail: null,
  currentDemand: [],
  AssistantDirector:[]

}
export const demandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_ASSISTANT_DIRECTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ACTIVE_ASSISTANT_DIRECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        AssistantDirector: action.payload
      };
    case GET_ACTIVE_ASSISTANT_DIRECTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case ADD_DEMAND_DATA:
  // console.log(action.payload);
  const existingProductIndex = state.data.findIndex(product => product._id === action.payload._id);

  if (existingProductIndex !== -1) {
    // If the product already exists, update its quantity
    const updatedData = [...state.data];
    updatedData[existingProductIndex] = {
      ...updatedData[existingProductIndex],
      quantity: parseInt(updatedData[existingProductIndex].quantity) + parseInt(action.payload.quantity)
    };

    return {
      ...state,
      data: updatedData
    };
  } else {
    // If the product doesn't exist, add it to the data array
    return {
      ...state,
      data: [...state.data, action.payload]
    };
  }

      
    case DEMAND_DETAIL:
      return { 
        ...state, 
        detail: action.payload 
      };
    case CLEAR_DEMAND_DATA:
      return {
        ...state,
        data: [], // Clear the demand data but keep other state properties
        currentDemand: null, // Reset other properties if needed
        detail: null,
        error: null,
        loading: false
      };
    case CURRENT_DEMAND:
      return {
        ...state, 
        currentDemand: action.payload 
      };
    case UPDATE_DATA:
      return { 
        ...state, 
        data: action.payload 
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        detail: null, // Clear only the detail property
        error: null,
        loading: false
      };
    default:
      return state;
  }
};






export const allProductReducer = (state = {allProduct: []}, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_FROM_LOCATION_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS:
      console.log(action.payload);
      return {
        loading: false,
        allProduct: action.payload,
      };
    case GET_ALL_PRODUCT_FROM_LOCATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
      case CLEAR_ERROR:
        return{
            ...state,
            error:null
        }
    default:
      return state;
  }
};
export const allLocationReducer = (state = {allLocation: []}, action) => {
  switch (action.type) {
    case GET_ALL_LOCATION_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_LOCATION_SUCCESS:
      return {
        loading: false,
        allLocation: action.payload,
      };
    case GET_ALL_LOCATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    default:
      return state;
  }
};


export const postDemandReducer = (state = {postDemand: []}, action) => {
  switch (action.type) {
    case SAVE_DEMAND_REQUEST:
      return {
        postDemandLoading: true,
      };
    case SAVE_DEMAND_SUCCESS:
      return {
        postDemandLoading: false,
        postDemand: action.payload,
      };
    case SAVE_DEMAND_FAIL:
      return {
        postDemandLoading: false,
        postDemandError: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                postDemandError:null
            }
    default:
      return state;
  }
};


export const updateDemandAndProductStatusQuantityReducer = (state = {updateDemandAndProduct: []}, action) => {
  switch (action.type) {
    case UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_REQUEST:
      return {
        updateDemandAndProductLoading: true,
      };
    case UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_SUCCESS:
      return {
        updateDemandAndProductLoading: false,
        updateDemandAndProduct: action.payload,
      };
    case UPDATE_DEMAND_AND_PRODUCT_STATUS_QUANTITY_FAIL:
      return {
        updateDemandAndProductLoading: false,
        updateDemandAndProductError: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                updateDemandAndProductError:null
            }
    default:
      return state;
  }
};



export const allDemandReducer = (state = {allDemand: [],AssistantDirector:[],currentDemand:[]}, action) => {
  switch (action.type) {
    case UPDATE_DEMAND_STATUS_REQUEST:
    case GET_ALL_DEMAND_REQUEST:
    case GET_DEMAND_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DEMAND_STATUS_SUCCESS:
    case GET_ALL_DEMAND_SUCCESS:
      return {
        ...state,
        loading: false,
        allDemand: action.payload,
      };
      case GET_DEMAND_BY_ID_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        currentDemand: action.payload,
      };
    case GET_ALL_DEMAND_FAIL:
    case UPDATE_DEMAND_STATUS_FAIL:
    case GET_DEMAND_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    default:
      return state;
  }
};


export const getPreviewDemandReducer = (state = {previewDemand: []}, action) => {
  switch (action.type) {
    case GET_PREVIEW_DEMAND_REQUEST:
      return {
        ...state,
        previewDemandLoading: true,
      };
    case GET_PREVIEW_DEMAND_SUCCESS:
      return {
        ...state,
        previewDemandLoading: false,
        previewDemand: action.payload,
      };
    case GET_PREVIEW_DEMAND_FAIL:
      return {
        ...state,
        previewDemandLoading: false,
        previewDemandError: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                previewDemandError:null
            }
    default:
      return state;
  }
};

export const postDemandQuantityReducer = (state = {message:''}, action) => {
  switch (action.type) {
    case POST_DEMAND_QUANTITY_REQUEST:
      return {
        loading: true,
      };
    case POST_DEMAND_QUANTITY_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case POST_DEMAND_QUANTITY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
      case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
      case CLEAR_MESSAGE:
            return{
                ...state,
                message:null,
                error:null
            }
    default:
      return state;
  }
};

export const getDemandConsolidatedReducer = (state = { demandConsolidateRecord: []}, action)=>{
  switch (action.type){
      case GET_DEMAND_CONSOLIDATED_RECORD_REQUEST:
          return{
            demandConsolidateRecordLoading: true,
            demandConsolidateRecord: [],
          };
      case GET_DEMAND_CONSOLIDATED_RECORD_SUCCESS: 
          return {
            demandConsolidateRecordLoading: false,
            demandConsolidateRecord: action.payload
          }
      case GET_DEMAND_CONSOLIDATED_RECORD_FAIL:
          return {
            demandConsolidateRecordLoading: false,
            demandConsolidateRecordError: action.payload,
          }
      case CLEAR_MESSAGE:
          return {
              ...state,
              demandConsolidateRecordError: null,
          }
          default:
          return state;
          
  }
}


import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError, getAllProductType, addProduct, addProducts } from '../../../actions/productAction';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { useAlert } from 'react-alert';
import { getAllCompany } from '../../../actions/companyAction';
import { ADD_PRODUCT_SUCCESS } from '../../../Redux/constants/productConstants';
import TableComponentId from '../../Table Component/tableComponent';

const AddProduct = () => {
    const navigate = useNavigate()
    const alert = useAlert();
    const { addProductLoading, addProduct, addProductError,  } = useSelector((state) => state.addProduct);
    const {  allProductType,allProduct, message, error } = useSelector((state) => state.product);
    const [filters, setFilters] = useState({
        namee: '',
        specification: '',
      });
    const { allCompany } = useSelector((state) => state.company);
    const dispatch = useDispatch();

    // State variables for product details
    const [name, setName] = useState('');
    const [specifications, setSpecifications] = useState('');
    const [description, setDescription] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');

    const handleClear = () => {
        setName('');
        setSpecifications('');
        setDescription('');
        setSelectedType('');
        setSelectedCompany('');
    };
    const handleViewProductClick = () => {
        navigate('/products')
    }


    useEffect(() => {
        dispatch(getAllProductType());
        dispatch(getAllCompany());
    }, [dispatch]);

    const handleAddProduct = (e) => {
        e.preventDefault();
        const myForm = new FormData();
        myForm.append('name', name);
        myForm.append('specifications', specifications);
        myForm.append('description', description);
        myForm.append('type_id', selectedType);
        myForm.append('company_id', selectedCompany);
        dispatch(addProducts(myForm));
        setName('');
        setSpecifications('');
        setDescription('');
        setSelectedType('');
        setSelectedCompany('');
    };

    useEffect(() => {
        if (addProductError) {
            alert.error(addProductError);
            dispatch(clearError());
        } else if (addProduct?.message) {
            dispatch({ type: ADD_PRODUCT_SUCCESS, payload: []
             })
             console.log(addProduct)
            alert.success(addProduct?.message);
        }
    }, [addProductError, addProductLoading, addProduct]);




    const { roleTask } = useSelector(
        (state) => state.userData
      );
      var task = false;
      task = roleTask.find((e) => e?.task_id?.name === "View Product" && e.status === true);


    const filteredProducts = useMemo(() => {
        console.log(allProduct, filters)
       return  allProduct === "No Item Found" ? [] : allProduct?.filter((product) => {
        const matchesName = product?.name?.toLowerCase()?.includes(filters?.namee.toLowerCase());
        // const matchesDescription = product?.description?.toLowerCase()?.includes(filters?.specifications.toLowerCase());
        const matchesSpecification = product?.specifications?.toLowerCase()?.includes(filters?.specification.toLowerCase());
        // const matchesType = filters.selectedType ? product?.type_id?.name === filters?.selectedType : true;
        // const matchesCompany = filters.selectedCompany ? product?.company_id?.name === filters?.selectedCompany : true;
        return matchesName && matchesSpecification
      })
       
      }, [allProduct, filters]);

      const handleFilterChange = (e) => {
        console.log(e)
        setFilters({
          ...filters,
          [e.target.name]: e.target.value,
        });
      };
    
      
    const columns = [
        { field: "name", label: "Name" },
        { field: "specifications", label: "Specification" },
        { field: "type_id.name", label: "Type"},
        { field: "company_id.name", label: "Company"}
      ];
    

    return (
    <>
        <div className={`Product`}>
            <div className="secondContainer">
                <div className="contentt-box">
                    <div className="heading-container">
                        <h3>{"Products"}</h3>
                    </div>
                </div>
                {
                    !addProductLoading ? (
                        <>
                           <div className="form">
                    <div className="formRow">
                        <div className="inputSection">
                            <label className='required'>Name</label>
                            <input
                                type="text"
                                placeholder="Enter product name "
                                name='namee'
                                value={name}
                                onChange={(e) =>{setName(e.target.value)
                                    handleFilterChange(e)
                                }}
                                required
                                className='yellow_border'
                            />
                        </div>
                        <div className="inputSection">
                            <label className='required'>Specifications</label>
                            <input
                                type="text"
                                name='specification'
                                placeholder="Enter specifications"
                                value={specifications}
                                onChange={(e) => {setSpecifications(e.target.value)
                                    handleFilterChange(e)
                                }}
                                className='yellow_border'
                            />
                        </div>
                    </div>
                    <div className="formRow">
                        <div className="inputSection">
                            <label className='required'>Description</label>
                            <input
                                type="text"
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className='yellow_border'
                            />
                        </div>
                        <div className="inputSection">
                            <label className='required'>Type</label>
                            <select
                                value={selectedType}
                                className='yellow_border'
                                onChange={(e) => setSelectedType(e.target.value)}
                            >
                                <option value="">Select Type</option>
                                    {allProductType?.map((type) => (
                                        <option key={type.id} value={type._id}>{type.name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="formRow">
                        <div className="inputSection">
                            <label className='required'>Company</label>
                            <select
                                className="search-input yellow_border"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}
                            >
                                <option value="">Select Company</option>
                                    {allCompany?.map((company) => (
                                <option key={company.id} value={company._id}>{company.name}</option>
                                    ))}
                            </select>
                        </div>
                        
                    </div>
                    <div className="buttonRow">
                        <button type="submit" className="button button-back" onClick={handleViewProductClick}>Back</button>
                        <button type="submit" className={`button button-add-product`} onClick={handleAddProduct}>Add Product</button>
                    </div>
                </div>
                        </>
                    ) : (<>
                        <Loader />
                        </>)
                }
             
                <div className="form-table-container">
            <TableComponentId
                data={filteredProducts}
                columns={columns}
            />
                </div>
            </div>
        </div>
    </>
        // loading ? (
        //     <Loader />
        // ) : (
        //     <div className="main-page-container">
        //         <div className='pageName_And_Button'>
        //             <h3>Add Product</h3>
        //             {task ? <button className='button-yellow' onClick={handleViewProductClick}>View Product</button>: null}
                    
        //         </div>
        //         <form className="input-bar" onSubmit={handleAddProduct} >
        //             <div className='input-container'>
        //                 <div className='input-with-label'>
        //                     <label className='required'>Name</label>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter product name "
        //                         value={name}
        //                         onChange={(e) => setName(e.target.value)}
        //                         required
        //                         className='yellow_border'
        //                     />
        //                 </div>
        //                 <div className='input-with-label'>
        //                     <label className='required'>Specifications</label>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter specifications"
        //                         value={specifications}
        //                         onChange={(e) => setSpecifications(e.target.value)}
        //                         className='yellow_border'
        //                     />
        //                 </div>
        //                 <div className='input-with-label'>
        //                     <label className='required'>Description</label>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter description"
        //                         value={description}
        //                         onChange={(e) => setDescription(e.target.value)}
        //                         className='yellow_border'
        //                     />
        //                 </div>
        //             </div>
        //             <div className='input-container' style={{ marginTop: '20px' }}>

        //                 <div style={{display:'flex', width:'65%', gap:'7.55%'}}>
        //                     <div className='input-with-label'>
        //                         <label className='required'>Type</label>
        //                         <select
        //                             value={selectedType}
        //                             className='yellow_border'
        //                             onChange={(e) => setSelectedType(e.target.value)}
        //                         >
        //                             <option value="">Select Type</option>
        //                             {allProductType?.map((type) => (
        //                                 <option key={type.id} value={type._id}>{type.name}</option>
        //                             ))}
        //                         </select>
        //                     </div>
        //                     <div className='input-with-label' style={{ display: 'flex' }} >
        //                         <label className='required'>Company</label>
        //                         <select
        //                             className="search-input yellow_border"
        //                             value={selectedCompany}
        //                             onChange={(e) => setSelectedCompany(e.target.value)}
        //                         >
        //                             <option value="">Select Company</option>
        //                             {allCompany?.map((company) => (
        //                                 <option key={company.id} value={company._id}>{company.name}</option>
        //                             ))}
        //                         </select>
        //                     </div>
        //                 </div>
        //                 <div></div>
        //             </div>
        //             <div className="clear-and-Add-button-container">
        //                 <button type="submit" className="clear-And-Add-Record-button" onClick={handleClear}>Clear</button>
        //                 <button type="submit" className="clear-And-Add-Record-button" onClick={handleAddProduct}>Add Product</button>
        //             </div>
        //         </form>
        //     </div>
        // )
    );
};

export default AddProduct;
